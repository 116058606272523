import Button from '../../components/button';
import { ReactComponent as IconTicketButton } from '../../assets/images/icons/icon-ticket-button.svg';
import NumberInput from '../../components/number-input';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CheckoutData, HeroImageType, OfferType, TimeSlotType } from '../../types/ticket';
import ImgWithFallback from '../../components/ImgWithFallback';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { emptyDate, currency } from '../../config/constants';
import { Availability } from '../../types/availability';
import Carousel from '../../components/carousel';
import useCarousel from '../../components/carousel/useCarousel';
import { Trans, useTranslation } from 'react-i18next';
import useViewport from '../../hooks/use-viewport';
import useCarouselTimeSlot from '../../components/carousel-timeslots/useCaruselTimeslots';
import CarouselTimeSlot from '../../components/carousel-timeslots';
import { addDays, isEqual } from 'date-fns';
import { getDates } from '../../lib/utils';


type TicketProps = {
  offer: OfferType;
  heroImage: HeroImageType;
  isSelected: boolean;
  selectHandler: (value: number) => void;
  editOfferObj?: CheckoutData;
  handleCheckoutBtn: () => void;
  availabilities: Availability[];
  utcOffset: number;
  loader: boolean
  loaderTimeSlot: boolean
  handleChangePeopleCount: (value: any, name: "adultCount" | "childCount" | "infantCount") => void;
  selectDate: (date: Date) => void;
  selectTimeSlot: (slot: TimeSlotType) => void
  dateStart: Date | string;
  dateEnd: Date | string;
  fetchAvailability: (date: Date, to: Date) => Promise<void>;
};

const Offer = ({
                 editOfferObj,
                 offer,
                 isSelected,
                 selectHandler,
                 heroImage,
                 handleCheckoutBtn,
                 availabilities,
                 utcOffset,
                 loader,
                 loaderTimeSlot,
                 handleChangePeopleCount,
                 selectTimeSlot,
                 selectDate,
                 dateStart, dateEnd,fetchAvailability
               }: TicketProps) => {

  const [t] = useTranslation();
  const startDate = new Date(dateStart) < new Date() ? new Date() : new Date(dateStart);


  const [dates, setDates] = useState<Date[]>(getDates(startDate, new Date(dateEnd), 10));


  const { priceFromAdult, priceFromChild, pricePerTrip } = useMemo(() => {
    // Get the first price entry from the offer's prices array.
    const firstPrice = offer.pricingPeriods[0] || { pricePerAdult: 0, pricePerChild: 0, pricePerTrip: 0 };

    // Return the prices directly from the first entry.
    return {
        priceFromAdult: firstPrice.pricePerAdult,
        priceFromChild: firstPrice.pricePerChild,
        pricePerTrip: firstPrice.pricePerTrip
    };
}, [offer]);



  const { adultAgeToolTip, childAgeToolTip, infantAgeToolTip } = useMemo(() => {
    // Check if children are allowed and set adultAgeToolTip accordingly
    const adultToolTip = offer.childrenAllowed
      ? t('ticket.adultToolTip', {age: offer.childAge})
      : t('ticket.adultToolTip', {age: offer.infantAge});

    // Set tooltips for child and infant
    const childToolTip = t('ticket.childToolTip', {age: offer.childAge});
    const infantToolTip = t('ticket.childToolTip', {age: offer.infantAge});

    return { adultAgeToolTip: adultToolTip, childAgeToolTip: childToolTip, infantAgeToolTip: infantToolTip };
  }, [offer]);


  const total = useMemo(
    () => {
      const price = (editOfferObj && editOfferObj.selectedPrice) || (offer.pricingPeriods.length && offer.pricingPeriods[0]);
      return editOfferObj && price
        ? (
          editOfferObj.adultCount * price.pricePerAdult +
          editOfferObj.childCount * price.pricePerChild +
          price.pricePerTrip
        ).toFixed(0)
        : 0;
    },
    [editOfferObj, offer.prices]
  );

  const excludeDates = useMemo(
    () =>
      availabilities.reduce((prev: Date[], cur) => {
        if (editOfferObj?.timeSlot?.id === cur.timeSlotId && cur.left < 1) {
          prev.push(new Date(cur.date));
        }
        return prev;
      }, []),
    [availabilities, editOfferObj?.timeSlot]
  );

  useEffect(() => {
    if (
      editOfferObj?.selectedDate &&
      !dates.some((date) => editOfferObj.selectedDate && isEqual(date, editOfferObj.selectedDate))
    ) {
      const startDate = addDays(dates[dates.length - 1], 1);
      const endDate = addDays(editOfferObj.selectedDate, 7);
      const newDates = getDates(startDate, endDate);
      setDates((prev) => prev.concat(newDates));
    }
  }, [selectDate]);

  const { carouselItems } = useCarousel(availabilities,
    utcOffset,
    editOfferObj?.offer?.timeSlots,
    dates,
    editOfferObj?.offer?.isExternal);

  const timeSlotsCarouselItems = useCarouselTimeSlot(
    availabilities,
    utcOffset,
    offer?.timeSlots,
    editOfferObj?.selectedDate
  );

  const { width } = useViewport();

  const offerRef = useRef(null);

  const onSelectHandler = () => {
    selectHandler(offer.id);
    setTimeout(() => {

      if (offerRef && offerRef.current) {
        // @ts-ignore
        const boundingClientRect = offerRef.current.getBoundingClientRect();
        console.log(boundingClientRect);
        window && window.scrollBy({
          top: boundingClientRect.top - (width > 760 ? 140 : 80),
          left: 0,
          behavior: 'smooth'
        });
      }
    }, 0);
  };
  

  return (
    <div className='ticket-item'>
      <div ref={offerRef} className='ticket-item--preview' onClick={onSelectHandler}>
        <div className='ticket-item__image'>
          {(offer.heroImage?.srcSet || heroImage?.srcSet) && <ImgWithFallback
            data={offer.heroImage?.srcSet || heroImage?.srcSet}
            alt={offer.heroImage?.caption || heroImage?.caption}
          />}
        </div>
        <div className='ticket-item__title ticket-item-container'>
          <h3>{offer.name}</h3>
        </div>
        <div className='ticket-item__price ticket-item-container'>
          <div className='ticket-item__price-wrap'>
            {!priceFromChild && !pricePerTrip && (
              <div className='ticket-item__price-item'>
                <div className='ticket-item__price-sum'>
                  {priceFromAdult.toFixed(0)}
                  <span>&nbsp;{currency}</span>
                </div>
                <span>{t('checkout.perPerson')}</span>
              </div>
            )}
            {!!priceFromChild && (
              <div className='ticket-item__price-item'>
                <div className='ticket-item__price-sum'>
                  {priceFromAdult.toFixed(0)}
                  <span>&nbsp;{currency}</span>
                </div>
                <span>{t('adult')}</span>
              </div>
            )}
            {!!priceFromChild && (
              <div className='ticket-item__price-item'>
                <div className='ticket-item__price-sum'>
                  {priceFromChild.toFixed(0)}
                  <span>&nbsp;{currency}</span>
                </div>
                <span>{t('ticket.child')}</span>
              </div>
            )}
            {!!pricePerTrip && (
              <div className='ticket-item__price-item'>
                <div className='ticket-item__price-sum'>
                  {pricePerTrip.toFixed(0)}
                  <span>&nbsp;{currency}</span>
                </div>
                <span>{t('ticket.perCar')}</span>
              </div>
            )}
          </div>
        </div>
        <div className='ticket-item__button'>
          <Button
            active={isSelected}
            iconLeft={() => <IconTicketButton />}
            label={isSelected ? t('ticket.selected') : t('ticket.moreInfo')}
          />
        </div>
      </div>

      <div className={`ticket-item--detail-wrap ${isSelected ? 'ticket-item_open' : 'ticket-item_close'}`}>
        <div className='ticket-item--detail'>
          <div className='ticket-item--detail--left'>
            <div className='ticket-item--detail_title'>{t('ticket.ticketInformation')}</div>

            <div
              className='ticket-item__description'
              dangerouslySetInnerHTML={{ __html: editOfferObj?.offer?.description || '' }}
            ></div>
          </div>
          <div className='ticket-item--detail--right'>
            <div className='ticket-item--detail_title'>{t('ticket.checkAvailability')}</div>
            {!offer.childrenAllowed && (
              <NumberInput
                label={t('ticket.generalAdmission')}
                tooltip={adultAgeToolTip}
                price={editOfferObj?.selectedPrice?.pricePerAdult || priceFromAdult}
                count={editOfferObj?.adultCount}
                onChange={(value) => handleChangePeopleCount(value, 'adultCount')}
                minValue={1}
              />
            )}
            {offer.childrenAllowed && (
              <NumberInput
                label={t('adult')}
                tooltip={adultAgeToolTip}
                price={editOfferObj?.selectedPrice?.pricePerAdult || priceFromAdult}
                count={editOfferObj?.adultCount}
                onChange={(value) => handleChangePeopleCount(value, 'adultCount')}
                minValue={1}
              />
            )}
            {offer.childrenAllowed && (
              <NumberInput
                label={t('child')}
                tooltip={childAgeToolTip}
                price={editOfferObj?.selectedPrice?.pricePerChild || priceFromChild}
                count={editOfferObj?.childCount}
                onChange={(value) => handleChangePeopleCount(value, 'childCount')}
                minValue={0}
              />
            )}
            {offer.infantsAllowed && (
              <NumberInput
                label={t('infant')}
                tooltip={infantAgeToolTip}
                price={0}
                count={editOfferObj?.infantCount}
                onChange={(value) => handleChangePeopleCount(value, 'infantCount')}
                minValue={0}
              />
            )}
            {editOfferObj?.selectedPrice && editOfferObj.selectedPrice.pricePerTrip > 0 && (
              <div className='ticket-item-per-trip'>
                <div className='ticket-item-per-trip__label'>
                  <Trans i18nKey={`ticket.pricePerVehicle`}>
                    Price per Vehicle * <span> Max 6 people</span>
                  </Trans>
                </div>
                <div
                  className='ticket-item-summary__summ'>{editOfferObj.selectedPrice.pricePerTrip || pricePerTrip} {currency}
                </div>
              </div>
            )}

            {editOfferObj?.offer?.bookingType !== 2 && (
              <div className='ticket-item-time'>
                <div className='ticket-item-time__label'>
                  {offer?.timeSlots?.length === 1 && !offer?.timeSlots[0].isAnyTime ? (
                    <>
                      <div>{t('ticket.time')}</div>
                      <div className='ticket-item-time__label_right'>{`${format(
                        addMinutes(emptyDate, editOfferObj?.timeSlot?.startTime || 0),
                        'HH-mm'
                      )} - ${format(addMinutes(emptyDate, editOfferObj?.timeSlot?.endTime || 0), 'HH-mm')}`}</div>
                    </>
                  ) : (
                    <>{t('ticket.selectTime')}:</>
                  )}
                </div>
              </div>
            )}
            {loader ? <div>Loading...</div> :
              <>
                {editOfferObj?.offer?.bookingType !== 2 && (
                  <Carousel
                    carouselItems={carouselItems}
                    selectedDate={editOfferObj?.selectedDate}
                    onChange={selectDate}
                    excludeDates={excludeDates}
                    isExternalTour={!!editOfferObj?.offer?.isExternal}
                    fetchAvailability={fetchAvailability}
                  />
                )}
                {loaderTimeSlot ? <div>Loading...</div> :
                !!editOfferObj?.offer?.isExternal && !!editOfferObj?.selectedDate && timeSlotsCarouselItems?.length === 0 ?
                  <div>{t('checkout.noTimeSlots')}</div>
                  : !!timeSlotsCarouselItems?.length &&
                  (timeSlotsCarouselItems?.length > 1 || timeSlotsCarouselItems[0].timeSlot.isAnyTime) &&
                  editOfferObj?.selectedDate && (
                    <div className='select-time-slot'>
                      <div>{t('ticket.selectTime')}</div>
                      <CarouselTimeSlot
                        carouselItems={timeSlotsCarouselItems}
                        currentTimeSlot={editOfferObj?.timeSlot}
                        onChange={selectTimeSlot}
                        isPerTrip={
                          !!editOfferObj?.selectedPrice?.pricePerTrip && editOfferObj?.selectedPrice?.pricePerTrip > 0
                        }
                      />
                    </div>
                  )}
                {editOfferObj?.offer?.bookingType === 2 &&
                editOfferObj?.ticketStock?.validUntil && (
                  <span>{t('ticket.openDated', {date: format(
                    new Date(editOfferObj?.ticketStock?.validUntil) || new Date(),
                    'dd.MM.yyyy'
                  )})}</span>
                )}
                <div className='ticket-item-summary'>
                  <div className='ticket-item-summary__label'>
                    <Trans i18nKey={`ticket.total`}>
                      Total
                    </Trans>
                  </div>
                  <div className='ticket-item-summary__summ'>{total} {currency}</div>
                </div>
              </>}
          </div>
        </div>
        {editOfferObj?.offer?.bookingType !== 2 && (
          <Button
            onClickHandler={handleCheckoutBtn}
            disabled={
              !editOfferObj?.selectedDate ||
              ((editOfferObj?.selectedDate && editOfferObj?.offer?.isExternal &&
                (!timeSlotsCarouselItems?.length || !editOfferObj.timeSlot)) ||
                (editOfferObj.timeSlot?.isAnyTime && !editOfferObj.timeSlot?.availability?.left) ||
                (!editOfferObj.timeSlot?.isAnyTime && timeSlotsCarouselItems?.length === 1 && !timeSlotsCarouselItems[0]?.availability?.left) ||
                (!editOfferObj.timeSlot?.isAnyTime && timeSlotsCarouselItems?.length === 0 && !editOfferObj.availability?.left)
              )
            }
            label={!editOfferObj?.selectedDate ||  (!!editOfferObj?.offer?.isExternal && timeSlotsCarouselItems?.length === 0) ? t('ticket.selectDate') : t('ticket.checkout') }
            fullWidth
            color='blue'
            size='large'
          />
        )}
        {editOfferObj?.offer?.bookingType === 2 && (
          <Button
            onClickHandler={handleCheckoutBtn}
            disabled={false}
            label={t('ticket.checkout')}
            fullWidth
            color='blue'
            size='large'
          />
        )}
      </div>
    </div>
  );
};

export default Offer;
